const URL_API = {
    // ABSENCE SERVICE
    ABSENCE_CONFIGURATION_EDIT: '/v1_4/absence/configuration/edit',
    ABSENCE_LOCATION_CREATE: '/v1_4/absence/location/new',
    ABSENCE_LOCATION_DELETE: '/v1_4/absence/location/delete',
    ABSENCE_LOCATION_DETAIL: '/v1_4/absence/location/detail',
    ABSENCE_LOCATION_EDIT: '/v1_4/absence/location/edit',
    ABSENCE_LOCATION_EDIT_STATUS: '/v1_4/absence/location/edit/status',
    ABSENCE_LOCATION_IMPORT: '/v1_4/absence/location/import',
    ABSENCE_LOCATION_LIST: '/v1_4/absence/location/list',
    ABSENCE_LOCATION_STATUS: '/v1_4/absence/location/configuration/edit',
    ABSENCE_MEMBER_LIST: '/v1_4/absence/location/member/list',
    ABSENCE_MONITORING_LIST: '/v1_4/absence/monitor',

    // ACTION SERVICE
    ACTION_COMPLETED_COUNT: '/v1_4/action/completed/count',
    ACTION_LIST: '/v1_4/action/target/list',
    ACTION_LIST_UPDATE: '/v1_4/action/target/set',
    
    // AUTH SERVICE
    AUTH_GOOGLE_LOGIN: '/v1_4/auth/googlelogincms',
    AUTH_EMAIL_PASSWORD: '/v1_4/auth/locallogincms',

    // CASH ADVANCE SERVICE
    CASH_ADVANCE_APPROVAL_LIST: '/v1_4/cash_advance/approval/list',
    CASH_ADVANCE_APPROVE: '/v1_4/cash_advance/approval/approve',
    CASH_ADVANCE_EDIT: '/v1_4/cash_advance/edit',
    CASH_ADVANCE_EXPORT: '/v1_4/cash_advance/export',
    CASH_ADVANCE_HISTORY: '/v1_4/cash_advance/history',
    CASH_ADVANCE_IMPORT: '/v1_4/cash_advance/import',
    CASH_ADVANCE_REJECT: '/v1_4/cash_advance/approval/reject',

    // CHAT SERVICE
    CHAT_DELETE: '/v1_4/chat/delete',
    CHAT_DETAIL: '/v1_4/chat/get',
    CHAT_LIST: '/v1_4/chat/list',
    CHAT_MESSAGE: '/v1_4/chat/message',
    CHAT_READ: '/v1_4/chat/read',
    CHAT_SEND: '/v1_4/chat/send',
    CHAT_UNREAD: '/v1_4/chat/unread',

    // CUSTOMER SERVICE
    CUSTOMER_ADDRESS_TYPE: '/v1_4/customer/addresstype',
    CUSTOMER_ASSIGNED_LIST: '/v1_4/customer/assigned/list',
    CUSTOMER_CMS_ADDRESS_CREATE: '/v1_4/customer/cms/addaddress',
    CUSTOMER_CMS_ADDRESS_DELETE: '/v1_4/customer/cms/deleteaddress',
    CUSTOMER_CMS_ADDRESS_EDIT: '/v1_4/customer/cms/editaddress',
    CUSTOMER_CMS_EDIT: '/v1_4/customer/cms/edit',
    CUSTOMER_COUNT: '/v1_4/customer/count',
    CUSTOMER_CREATE: '/v1_4/customer/new',
    CUSTOMER_DETAIL: '/v1_4/customer/get',
    CUSTOMER_EXPORT: '/v1_4/customer/export',
    CUSTOMER_HISTORY: 'v1_4/customer/history',
    CUSTOMER_IMPORT: '/v1_4/customer/import',
    CUSTOMER_LIST: '/v1_4/customer/list',
    CUSTOMER_CONFIGURATION_EDIT: '/v1_4/customer/member-reminder/config/edit',
    CUSTOMER_CONTRACT_MEMBER_OPTION_LIST: '/v1_4/customer/member-reminder/option/list',
    CUSTOMER_CONTRACT_MEMBER_REMINDER_UPDATE: '/v1_4/customer/member-reminder/edit',
    CUSTOMER_CONTRACT_MEMBER_LIST: '/v1_4/customer/member-reminder/list',
    CUSTOMER_FIELD_IMAGE_UPLOAD: '/v1_4/customer/cms/value/uploadimage',
    CUSTOMER_FIELD_DOCUMENT_UPLOAD: '/v1_4/customer/cms/value/uploaddocument',

    // CUSTOMER TEMPLATE SERVICE
    CUSTOMER_TEMPLATE_LIST: '/v1_4/customertemplate/list',

    // DASHBOARD SERVICE
    DASHBOARD_ACTIVATION_ACTIVE: '/v1_4/dashboard/activation/active',
    DASHBOARD_ACTIVATION_INACTIVE: '/v1_4/dashboard/activation/inactive',
    DASHBOARD_BARCHART_CREATE: '/v1_4/dashboard/barchart/add',
    DASHBOARD_BARCHART_DETAIL: '/v1_4/dashboard/barchart/detail',
    DASHBOARD_BARCHART_EDIT: '/v1_4/dashboard/barchart/edit',
    DASHBOARD_BARCHART_PREVIEW: '/v1_4/dashboard/barchart/preview',
    DASHBOARD_DETAIL: '/v1_4/dashboard/get',
    DASHBOARD_GAUGE_CREATE: '/v1_4/dashboard/gauge/add',
    DASHBOARD_GAUGE_DETAIL: '/v1_4/dashboard/gauge/detail',
    DASHBOARD_GAUGE_EDIT: '/v1_4/dashboard/gauge/edit',
    DASHBOARD_GAUGE_PREVIEW: '/v1_4/dashboard/gauge/preview',
    DASHBOARD_LIST: '/v1_4/dashboard/list',
    DASHBOARD_NUMBER_CREATE: '/v1_4/dashboard/number/add',
    DASHBOARD_NUMBER_DETAIL: '/v1_4/dashboard/number/get',
    DASHBOARD_NUMBER_PREVIEW: '/v1_4/dashboard/number/preview',
    DASHBOARD_PIECHART_CREATE: '/v1_4/dashboard/piechart/add',
    DASHBOARD_PIECHART_DETAIL: '/v1_4/dashboard/piechart/detail',
    DASHBOARD_PIECHART_EDIT: '/v1_4/dashboard/piechart/edit',
    DASHBOARD_PIECHART_PREVIEW: '/v1_4/dashboard/piechart/preview',

    // FACE RECOGNITION SERVICE
    FACE_RECOGNIION_RESET: '/v1_4/facerecognition/delete',

    // FEEDBACK SERVICE
    FEEDBACK_DETAIL: '/v1_4/feedback/getmember',
    FEEDBACK_FORM_DETAIL: '/v1_4/feedback/feedbackdatas?slug=:slug',
    FEEDBACK_FORM_SUBMIT: '/v1_4/feedback/feedbackrating?slug=:slug',
    FEEDBACK_GENERATE: '/v1_4/feedback/generate',
    FEEDBACK_LIST: '/v1_4/feedback/list',

    // GROUP MEMBER SERVICE
    GROUP_MEMBER_ACCESS: '/v1_4/groupmember/access/list',
    GROUP_MEMBER_ACCESS_EDIT: '/v1_4/groupmember/access/multipleaccess',
    GROUP_MEMBER_CREATE: '/v1_4/groupmember/new',
    GROUP_MEMBER_CUSTOMER: '/v1_4/groupmember/customer/list',
    GROUP_MEMBER_CUSTOMER_EDIT: '/v1_4/groupmember/customer/apply',
    GROUP_MEMBER_DETAIL: '/v1_4/groupmember/detail',
    GROUP_MEMBER_EDIT: '/v1_4/groupmember/edit',
    GROUP_MEMBER_LIST: '/v1_4/groupmember/list',

    // ITEM CATEGORY SERVICE
    ITEM_CATEGORY_CREATE: '/v1_4/itemcategory/addcategory',
    ITEM_CATEGORY_DELETE: '/v1_4/itemcategory/delete',
    ITEM_CATEGORY_EDIT: '/v1_4/itemcategory/edit',
    ITEM_CATEGORY_LIST: '/v1_4/itemcategory/list',

    // ITEM SERVICE
    ITEM_CREATE: '/v1_4/item/additem',
    ITEM_DELETE: '/v1_4/item/delete',
    ITEM_EDIT: '/v1_4/item/edit',
    ITEM_IMPORT: '/v1_4/item/import',
    ITEM_LIST: '/v1_4/item/list',
    ITEM_ORDER: 'v1_4/item/dashboard/order',
    ITEM_REVENUE: 'v1_4/item/dashboard/revenue',
    ITEM_SOLD: 'v1_4/item/dashboard/sold',
    ITEM_STATUS: '/v1_4/item/setactivation',
    ITEM_TEMPLATE: '/v1_4/item/template',
    ITEM_TOP: 'v1_4/item/statistics/sold',
    ITEM_TOP_CUSTOMER: 'v1_4/item/statistics/buying_customer',

    // LOOKUP SERVICE
    LOOKUP_LIST: '/v1_4/lookup/list',

    // LOOKUP VALUE SERVICE
    LOOKUP_VALUE_LIST: '/v1_4/lookupvalue/list',

    // MEMBER SERVICE
    MEMBER_ACCEPT: '/v1_4/member/accept',
    MEMBER_COUNT: '/v1_4/member/count',
    MEMBER_DECLINE: '/v1_4/member/decline',
    MEMBER_DEVICE_RESET: '/v1_4/member/devicereset',
    MEMBER_EDIT: 'v1_4/member/edit',
    MEMBER_EXPORT: '/v1_4/member/export',
    MEMBER_GEOFANCE_LIVE_TRACKING_EDIT: 'v1_4/member/geofanceactivate',
    MEMBER_LIST: '/v1_4/member/list',

    MEMBER_LIST_FILTER: '/v1_4/member/cms/list',

    // MEMBER PRIVILAGE SERVICE
    MEMBER_PRIVILAGE_LIST: '/v1_4/memberprivilege/list',

    // MENU PRIVILAGE SERVICE
    MENU_PRIVILAGE_LIST: '/v1_4/menuprivilege/list',

    // NEWS FEED SERVICE
    NEWS_FEED_LIST: '/v1_4/newsfeed/list',

    // NOTIFICATION SERVICE
    NOTIFICATION_LIST: '/v1_4/notification/list',
    NOTIFICATION_READ: '/v1_4/notification/read',
    NOTIFICATION_READ_ALL: '/v1_4/notification/mark-read',
    NOTIFICATION_UNREAD_COUNT: '/v1_4/notification/unread/count',

    // REPORT SERVICE
    REPORT_ABSENT_EXPORT: '/v1_4/report/export/absence',
    REPORT_BUILDER_COLUMN: '/v1_4/report/builder/column',
    REPORT_BUILDER_CREATE: '/v1_4/report/builder/new',
    REPORT_BUILDER_DELETE: '/v1_4/report/builder/delete',
    REPORT_BUILDER_FORMAT: '/v1_4/report/builder/format',
    REPORT_BUILDER_LIST: '/v1_4/report/builder/list',
    REPORT_BUILDER_PARAMETER: '/v1_4/report/builder/parameter',
    REPORT_BUILDER_PREVIEW: '/v1_4/report/builder/preview',
    REPORT_EXPORT: '/v1_4/report/export',
    REPORT_LIST: '/v1_4/report/list',
    REPORT_PARAMETER_LIST: '/v1_4/report/parameter/list',
    REPORT_PARAMETER_LOOKUP: '/v1_4/report/parameter/lookup',
    REPORT_PREVIEW: '/v1_4/report/preview',
    REPORT_CHECK: '/v1_4/report/check',
    REPORT_TICKET_EXPORT: '/v1_4/report/exportmobile',
    
    // RESULT SERVICE
    RESULT_DETAIL: '/v1_3/result/detail',
    RESULT_DETAIL_V4: '/v1_4/result/detail',
    RESULT_TASK_LIST: '/v1_4/result/task/list',
    RESULT_TICKET_LIST: '/v1_4/customerrequest/result/list',

    // TASK SERVICE
    TASK_ASSIGNMENT_COMPLETED: '/v1_4/task/assignment/completed',
    TASK_ASSIGNMENT_GET: 'v1_4/task/assignment/get',
    TASK_CANCEL: '/v1_4/task/cancel',
    TASK_CMS_CREATE: 'v1_4/task/cms/create',
    TASK_CMS_EDIT: 'v1_4/task/cms/edit',
    TASK_CMS_TEMPLATE_EDIT: 'v1_4/task/cms/edit/action',
    TASK_CMS_UPLOAD_DOCUMENT: 'v1_4/task/cms/edit/upload/document',
    TASK_CMS_UPLOAD_IMAGE: 'v1_4/task/cms/edit/upload/image',
    TASK_CMS_UPLOAD_SIGNATURE: 'v1_4/task/cms/edit/upload/signature',
    TASK_CREATE: 'v1_4/task/create',
    TASK_EDIT: 'v1_4/task/edit',
    TASK_HISTORY: 'v1_4/task/history',
    TASK_LIST: '/v1_4/task/planningrevamp',
    TASK_LIST_COUNT: '/v1_4/task/planningrevamp/count',
    TASK_MONITORING: '/v1_4/task/monitoring',
    TASK_MONTH: '/v1_4/task/month',
    TASK_PLANNING: '/v1_4/task/planning',
    TASK_PLANNING_COUNT: '/v1_4/task/planning/count',
    TASK_REASSIGN: '/v1_4/task/reassign',
    TASK_IMPORT: '/v1_4/task/import',

    // TASK TEMPLATE SERVICE
    TASK_TEMPLATE_CREATE: 'v1_4/task_template/create',
    TASK_TEMPLATE_DETAIL: 'v1_4/task_template/get',
    TASK_TEMPLATE_UPLOAD_DOCUMENT: 'v1_4/task_template/upload/document',
    TASK_TEMPLATE_UPLOAD_IMAGE: 'v1_4/task_template/upload/image',
    TASK_TEMPLATE_UPLOAD_SIGNATURE: 'v1_4/task_template/upload/signature',

    // TASK TYPE SERVICE
    TASK_TYPE_DETAIL: '/v1_4/tasktype/get',
    TASK_TYPE_LIST: '/v1_4/tasktype/list',
    TASK_TYPE_EXPORT_TEMPLATE: '/v1_4/tasktype/export_by_task_type',

    // TASK BULK
    TASK_BULK_RESCHEDULE: '/v1_4/task/bulk/reschedule',

    // TEAM SERVICE
    TEAM_DETAIL: '/v1_4/team/get',
    TEAM_LIST: '/v1_4/team/list',
    TEAM_UPDATE_THEME_LOGO: '/v1_4/team/edit/theme',

    // TRACKING SERVICE
    TRACKING_CONFIGURATION_EDIT: '/v1_4/tracking/configuration/status/edit',
    TRACKING_CONFIGURATION_STATUS_EDIT: '/v1_4/tracking/live/configuration/status/edit',
    TRACKING_DETAIL: '/v1_4/tracking/memberjourney',
    TRACKING_DETAIL_ELAPSED_TIME: '/v1_4/tracking/elapsedtime',
    TRACKING_DETAIL_STRAIGHT_DISTANCE: '/v1_4/tracking/straightdistance',
    TRACKING_LOCATION_CREATE: '/v1_4/tracking/live/location/new',
    TRACKING_LOCATION_DELETE: '/v1_4/tracking/live/location/delete',
    TRACKING_LOCATION_EDIT: '/v1_4/tracking/live/location/edit',
    TRACKING_LOCATION_IMPORT: '/v1_4/tracking/live/location/import',
    TRACKING_LOCATION_LIST: '/v1_4/tracking/live/location/list',
    TRACKING_LOCATION_MEMBER: '/v1_4/tracking/live/location/member',
    TRACKING_LOCATION_STATUS_EDIT: '/v1_4/tracking/live/location/edit/status',
    TRACKING_MEMBER_LIST: '/v1_4/tracking/live/member/list',
    TRACKING_RADIUS_EDIT: '/v1_4/tracking/configuration/radius/edit',

    // USER SERVICE
    USER_FIREBASE_TOKEN: '/v1_4/user/edit/firebase-token',
    USER_INVITATION_LIST: '/v1_4/user/invitation',

    // PERSONAL DASHBOARD
    PERSONAL_DASHBOARD_DETAIL: '/v1_4/personal-dashboard/get',

    // PERSONAL DASHBOARD PCO
    PERSONAL_DASHBOARD_PCO_RECOMMENDATION_STATUS: '/v1_4/custom/pco/dashboard/status-rekomendasi',
    PERSONAL_DASHBOARD_PCO_PEST_LIST: '/v1_4/custom/pco/lookup/list',
    PERSONAL_DASHBOARD_PCO_PEST_CAUGHT: '/v1_4/custom/pco/dashboard/jumlah-hama-tangkap',
    PERSONAL_DASHBOARD_PCO_PEST_DETAIL: '/v1_4/custom/pco/dashboard/detail-hama',
    PERSONAL_DASHBOARD_PCO_CHEMICAL: '/v1_4/custom/pco/dashboard/chemical-terpakai',
    PERSONAL_DASHBOARD_PCO_ANALYTIC: '/v1_4/custom/pco/dashboard/analytics',

    // LEAVE
    LEAVE_CATEGORY_LIST: '/v1_4/leave/category/list',
    LEAVE_CATEGORY_CREATE: '/v1_4/leave/category/create',
    LEAVE_CATEGORY_EDIT: '/v1_4/leave/category/edit',
    LEAVE_CATEGORY_ACTIVATE: '/v1_4/leave/category/activate',
    LEAVE_CATEGORY_EXCLUDE: '/v1_4/leave/category/excludefrom',
    LEAVE_MEMBER_QUOTA_RESET: '/v1_4/leave/quota/reset',
    LEAVE_MEMBER_QUOTA_LIST: '/v1_4/leave/quota/member/list',
    LEAVE_MEMBER_QUOTA_EDIT: '/v1_4/leave/quota/member/edit',
    LEAVE_MEMBER_QUOTA_DETAIL: '/v1_4/leave/quota/member',
    LEAVE_APPROVAL_AVAILIBILITY: '/v1_4/leave/approval/availibility',
    LEAVE_APPROVAL_STATUS_COUNT: '/v1_4/leave/approval/status/count',
    LEAVE_APPROVAL_LIST: '/v1_4/leave/approval/list',
    LEAVE_APPROVAL_ACTION: '/v1_4/leave/approval/action',
    LEAVE_ANNUAL_RESET: '/v1_4/leave/reset',

    // THEME
    THEME_LIST: '/v1_4/theme/list',

    // CUSTOMER REQUEST
    CUSTOMER_REQUEST_STATISTIC: '/v1_4/customerrequest/statistic',
    CUSTOMER_REQUEST_CALENDAR: '/v1_4/customerrequest/calendar',
    CUSTOMER_REQUEST_LIST: '/v1_4/customerrequest/list',
    CUSTOMER_REQUEST_ACCEPT: '/v1_4/customerrequest/accept',
    CUSTOMER_REQUEST_DETAIL: '/v1_4/customerrequest/ticket/detail',

    // LEADS
    LEADS_LIST: '/v1_4/lead/list',
    LEADS_IMPORT: '/v1_4/lead/import',
    LEADS_SOURCE_LIST: '/v1_4/lead/source/list',
    LEADS_INDUSTRY_LIST: '/v1_4/lead/industry/list',
    LEADS_CREATE: '/v1_4/lead/new',
    LEADS_FIELD_IMAGE_UPLOAD: '/v1_4/lead/value/uploadimage',
    LEADS_FIELD_DOCUMENT_UPLOAD: '/v1_4/lead/value/uploaddocument',
    LEADS_ACTIVITY_LIST: '/v1_4/lead/activity/list',
    LEADS_ACTIVITY_OWNER: '/v1_4/lead/getowner',
    LEADS_ACTIVITY_LEADS: '/v1_4/lead/getlead',
    LEADS_ACTIVITY_CREATE: '/v1_4/lead/activity/new',
    LEADS_TOTAL: '/v1_4/lead/total-lead',
    LEADS_UPDATE_STAGE: '/v1_4/lead/change-stage',
    LEADS_DETAIL: '/v1_4/lead/get',
    LEADS_EDIT: '/v1_4/lead/edit',
    LEADS_UPLOAD_DOCUMENT: '/v1_4/lead/value/uploaddocument',
    LEADS_UPLOAD_IMAGE: '/v1_4/lead/value/uploadimage',
    LEADS_DETAIL_ADD_NOTE: '/v1_4/lead/note/new',
    LEADS_DETAIL_DELETE_NOTE: '/v1_4/lead/note/delete',
    LEADS_DETAIL_ADD_ATTACH: '/v1_4/lead/attachment/new',
    LEADS_DETAIL_DELETE_ATTACH: '/v1_4/lead/attachment/delete',
    LEADS_DELETE: '/v1_4/lead/delete',

    BULLETIN_NEW  :'/v1_4/bulletinboard/new',
    BULLETIN_ACTIVATE :'/v1_4/bulletinboard/activate',
    BULLETIN_INACTIVATE :'/v1_4/bulletinboard/inactivate',
    BULLETIN_LIST :'/v1_4/bulletinboard/list',
    BULLETIN_EDIT :'/v1_4/bulletinboard/edit',
    BULLETIN_DETAIL :'/v1_4/bulletinboard/detail',
    BULLETIN_INACTIVE :'/v1_4/bulletinboard/inactivate',

    // REGION
    REGION_PROVINCE: '/v1_4/region/province/list',
    REGION_REGENCY: '/v1_4/region/regency/list',
    REGION_DISTRICT: '/v1_4/region/district/list',
    REGION_SUBDISTRICT: '/v1_4/region/subdistrict/list',
}

export default URL_API